<template>
  <div class="tile-cont">
    <div class="img-cont">
      <img :src="picture" id="card-img" alt="team" />
    </div>
    <div class="info-cont">
      <h4>{{ name }}</h4>
      <p>{{ title }}</p>
      <div class="socials">
        <div class="icon-wrapper">
          <a :href="linkedin">
            <font-awesome-icon :icon="['fab', 'linkedin-in']" class="icon" />
          </a>
        </div>
      </div>
    </div>
    <div class="hover-content">
      <h4>{{ name }}</h4>
      <p>{{ title }}</p>
      <div class="description" v-html="description"></div>
      <div class="socials">
        <div class="icon-wrapper">
          <a :href="linkedin">
            <font-awesome-icon :icon="['fab', 'linkedin-in']" class="icon" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.tile-cont {
  display: flex;
  flex-wrap: wrap;
  min-height: 400px;
  width: 250px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: 15px 10px;

  &:hover {
    .hover-content {
      top: 0%;
      transition: 0.5s ease;
    }
  }

  .img-cont {
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .info-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #33363e;
    width: 100%;
    padding: 12px 0px;

    h4,
    p {
      text-align: center;
      color: #ffffff;
      margin: 0px;
      width: 100%;
    }

    .socials {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 10px;

      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 20px;
        margin-bottom: 8px;

        .icon {
          font-size: 21px;
          color: #33363e;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .hover-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #545966;
    width: 220px;
    height: 100%;
    position: absolute;
    top: 100%;
    transition: 0.5s ease;
    padding: 5px 15px 15px 15px;

    .socials {
      display: flex;
      width: 100%;
      margin-top: 10px;

      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 20px;
        margin-bottom: 8px;

        .icon {
          font-size: 21px;
          color: #33363e;
          position: relative;
          top: 3px;
        }
      }
    }

    h3,
    h4,
    p {
      color: #ffffff;
      margin: 10px 0px;
      width: 100%;
    }

    p {
      margin: 0px;
      font-weight: bold;
    }
  }
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faLinkedinIn);

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    title: String,
    name: String,
    description: String,
    image: String,
    linkedin: String
  },
  data() {
    return {
      picture: "https://cdn.tickettote.com/media/img/about/" + this.image
    };
  }
};
</script>
